"use client"

import { getStoryblokApi } from "@/lib/storyblok"

const StoryblokProvider = ({ children }: { children: React.ReactNode }) => {
  getStoryblokApi()
  return children
}

export default StoryblokProvider
